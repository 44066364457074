import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const PHOTOVOLTAICS_LOCALES = {
	WOLTAIR_CZ: 'fotovoltaika',
	WOLTAIR_PL: 'fotowoltaika',
	WOLTAIR_DE: 'photovoltaik',
	WOLTAIR_IT: 'fotovoltaico'
}

export const PHOTOVOLTAICS = PHOTOVOLTAICS_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return PHOTOVOLTAICS === param
}) satisfies ParamMatcher
